<template>
    <v-container>
        <v-row>
            <v-col>
                <v-autocomplete v-model="new_user_to_chat" :items="users.filter(x => x.id != get_user.id)"
                    @change="selectUser(new_user_to_chat)" item-text="name" item-value="id" return-object dense
                    label="Start a new chat"></v-autocomplete>
                <v-list>
                    <v-list-item v-if="show_new_user && new_user_to_chat.name" @click="selectUser(new_user_to_chat)"
                        :class="selected_user && selected_user.id === new_user_to_chat.id
                            ? 'selected-item user-list-item py-2' : 'user-list-item py-2'">
                        <div class="mr-5">
                            <v-avatar :color="getColor(new_user_to_chat.name)" size="35">
                                <b style="color:white;">
                                    {{ new_user_to_chat.name[0] }}
                                </b>
                            </v-avatar>
                            <span v-if="new_user_to_chat.unread_count > 0" class="notification-bubble">
                                {{ new_user_to_chat.unread_count }}
                            </span>
                        </div>
                        <v-list-item-content :class="{ 'unread': new_user_to_chat.unread_count > 0 }">
                            <v-list-item-title>
                                {{ new_user_to_chat.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ new_user_to_chat.email }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-for="user in user_messages" :key="user.id" @click="selectUser(user)" :class="selected_user && selected_user.id === user.id
                        ? 'selected-item user-list-item py-2' : 'user-list-item py-2'">
                        <div class="mr-5">
                            <v-avatar :color="getColor(user.name)" size="35">
                                <b style="color:white;">
                                    {{ user.name[0] }}
                                </b>
                            </v-avatar>
                            <span v-if="user.unread_count > 0" class="notification-bubble">
                                {{ user.unread_count }}
                            </span>
                        </div>
                        <v-list-item-content :class="{ 'unread': user.unread_count > 0 }">
                            <v-list-item-title>
                                {{ user.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ user.email }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'UserList',
    props: [
        'users', 'user_messages'
    ],
    components: {
    },
    data: () => ({
        selected_user: null,
        show_new_user: false,
        new_user_to_chat: null,
    }),
    created() {
    },
    mounted() {
    },
    computed: {
        ...mapGetters({
            get_user: 'auth/get_user',
            get_selected_company: 'companies/get_selected_company',
            get_companies: 'companies/get_companies',
        }),
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        selectUser(user) {
            let color = this.getColor(user.name)
            this.selected_user = user
            this.$emit('userSelected', user, color)
            if (this.new_user_to_chat && !this.user_messages.some(messageUser => messageUser.id === user.id)) {
                this.show_new_user = true
            }
            else {
                this.show_new_user = false
                this.new_user_to_chat = {}
            }
        },
        getColor(name) {
            const letterColorMap = {
                A: 'red lighten-2',
                B: 'blue lighten-2',
                C: 'green lighten-2',
                D: 'orange lighten-2',
                E: 'purple lighten-2',
                F: 'pink lighten-2',
                G: 'teal lighten-2',
                H: 'amber lighten-2',
                I: 'indigo lighten-2',
                J: 'lime lighten-2',
                K: 'brown lighten-2',
                L: 'cyan lighten-2',
                M: 'deep-orange lighten-2',
                N: 'deep-purple lighten-2',
                O: 'light-blue lighten-2',
                P: 'light-green lighten-2',
                Q: 'blue-grey lighten-2',
                R: 'red darken-2',
                S: 'blue darken-2',
                T: 'green darken-2',
                U: 'orange darken-2',
                V: 'purple darken-2',
                W: 'pink darken-2',
                X: 'teal darken-2',
                Y: 'amber darken-2',
                Z: 'indigo darken-2'
            }
            const firstLetter = name.charAt(0).toUpperCase()
            return letterColorMap[firstLetter] || 'grey lighten-2'
        },
    },
    watch: {
        user_messages: function () {
            this.show_new_user = false
            this.new_user_to_chat = {}
        }
    },
}
</script>

<style lang="scss" scoped>
.user-list-item {
    cursor: pointer;
    height:50px;
}

.notification-bubble {
    position: absolute;
    top: 15px;
    right: 10px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 5px 5px;
    font-size: 0.75rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 20px;
    height: 20px;
}

.unread {
    font-weight: 600;
}

.selected-item {
    background-color: #f0f0f0;
}
</style>