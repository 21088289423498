<template>
    <v-container>
        <v-skeleton-loader type="image, list-item" v-if="loading"></v-skeleton-loader>
        <v-row v-else>
            <v-col>
                <v-card>
                    <v-card-title>
                        <v-list>
                            <v-list-item>
                                <v-list-item-avatar :color="color">
                                    <v-avatar>
                                        <b style="color:white;">
                                            {{ selected_user.name[0] }}
                                        </b>
                                    </v-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{ selected_user.name }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ selected_user.email }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-list id="messageList" style="max-height:500px;overflow-y:scroll;">
                                    <div v-for="(message, index) in messages" :key="message.id">
                                        <div v-if="index == messages.length - unread_count"
                                            class="zigzag-line-container">
                                            <div class="zigzag-line">
                                                <span class="unread-text">{{ unread_count }} unread</span>
                                            </div>
                                        </div>
                                        <v-list-item>
                                            <v-list-item-avatar v-if="message.sender_id !== get_user.id">
                                                <v-avatar v-if="shouldShow(index)" :color="color">
                                                    <b style="color:white;">
                                                        {{ selected_user.name[0] }}
                                                    </b>
                                                </v-avatar>
                                            </v-list-item-avatar>
                                            <v-list-item-content :class="{
                                                'sent-message': message.sender_id === get_user.id,
                                                'received-message': message.sender_id !== get_user.id
                                            }">
                                                <v-list-item-subtitle v-if="shouldShow(index)">
                                                    <b v-if="message.sender_id !== get_user.id" class="mr-3">
                                                        {{ selected_user.name }}
                                                    </b>
                                                    <small>
                                                        {{ $date(message.created_at).format('MMM DD, YYYY h:mm A') }}
                                                    </small>
                                                </v-list-item-subtitle>
                                                <v-list-item-title class="mt-1 mb-1">
                                                    {{ message.content }}
                                                </v-list-item-title>
                                                <v-list-item-subtitle v-if="message.id == null">
                                                    <i>sending...</i>
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </div>
                                </v-list>
                            </v-col>
                            <v-col cols="10">
                                <v-text-field v-model="new_message" label="Type a message" @keyup.enter="sendMessage"
                                    dense style="width:100%;"></v-text-field>
                            </v-col>
                            <v-col cols="2">
                                <v-btn @click="sendMessage" color="primary" icon>
                                    <v-icon>mdi-send</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'ChatWindow',
    props: [
        'loading', 'chat_refreshing', 'selected_user', 'color', 'messages', 'unread_count'
    ],
    components: {
    },
    data: () => ({
        new_message: '',
    }),
    created() {
    },
    mounted() {
    },
    computed: {
        ...mapGetters({
            get_user: 'auth/get_user',
            get_selected_company: 'companies/get_selected_company',
            get_companies: 'companies/get_companies',
        }),
    },
    methods: {
        selectUser(user) {
            this.$emit('userSelected', user)
        },
        scrollToBottom() {
            this.$nextTick(() => {
                var container = this.$el.querySelector("#messageList")
                if (container) {
                    container.scrollTop = container.scrollHeight
                }
            })
        },
        getMessages() {
            this.$emit('getMessages')
        },
        async sendMessage() {
            if (this.new_message.trim() !== '') {
                let message = {
                    sender_id: this.get_user.id,
                    receiver_id: this.selected_user.id,
                    content: this.new_message,
                    created_at: new Date().toISOString(),
                }
                this.$emit('pushMessage', message)
                this.scrollToBottom()
                this.new_message = ''
                this.$axios.post('api/messages/create_message', message)
                    .then(({ data }) => {
                        if (data.response) {
                            this.getMessages()
                        }
                    })
            }
        },
        shouldShow(index) {
            if (index === 0) return true
            const currentMessage = this.messages[index]
            const previousMessage = this.messages[index - 1]

            return this.$date(currentMessage.created_at).diff(this.$date(previousMessage.created_at), 'm') > 5
            || currentMessage.sender_id !== previousMessage.sender_id
        },
    },
    watch: {
        loading: function () {
            this.scrollToBottom()
            this.new_message = ''
        },
        chat_refreshing: function () {
            this.scrollToBottom()
        },
    },
}
</script>

<style lang="scss" scoped>
.sent-message {
    text-align: right;
    background-color: #ECF3FE;
    max-width: fit-content !important;
    margin-left: auto;
    margin-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
}

.received-message {
    text-align: left;
    background-color: #F2F2F2;
    max-width: fit-content !important;
    margin-right: auto;
    margin-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
}

.sent-message .v-list-item-content,
.received-message .v-list-item-content {
    display: inline-block;
    padding: 10px;
    border-radius: 10px;
    margin: 5px 0;
}

.zigzag-line-container {
    display: flex;
    justify-content: center;
    margin: 10px 0;
    position: relative;
}

.zigzag-line {
    width: 90%;
    height: 3px;
    background: repeating-linear-gradient(-45deg,
            transparent,
            transparent 5px,
            #7AA4E6 5px,
            #7AA4E6 10px);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.unread-text {
    position: absolute;
    background-color: white;
    padding: 0 10px;
    color: #7AA4E6;
    font-weight: 600;
    font-size: 12px;
}

:deep(::-webkit-scrollbar) {
    width: 10px;
}

:deep(::-webkit-scrollbar-thumb) {
    border-radius: 2px;
    /* background-color: rgba(0,0,0,.5); */
    background-color: #a5a5a5;
    color: #a5a5a5;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
</style>