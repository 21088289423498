<template>
    <v-container class="mt-10" fluid>
        <v-skeleton-loader type="table" v-if="loading"></v-skeleton-loader>
        <v-row v-else>
            <v-col cols="4">
                <UserList :users="users" :user_messages="user_messages" @userSelected="selectUser"></UserList>
            </v-col>
            <v-col cols="8">
                <ChatWindow v-if="selected_user" :selected_user="selected_user" :color="color" :messages="messages"
                    :loading="chat_loading" :chat_refreshing="chat_refreshing" :unread_count="unread_count" @getMessages="refreshMessages"
                    @pushMessage="pushMessage"></ChatWindow>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import UserList from '../modules/UserList.vue'
import ChatWindow from '../modules/ChatWindow.vue'
export default {
    name: 'MessageComponent',
    components: {
        UserList,
        ChatWindow,
    },
    data: () => ({
        loading: false,
        chat_loading: false,
        chat_refreshing: false,
        api_url: null,
        is_company_selected: false,
        users: [],
        user_messages: [],
        selected_user: null,
        color: null,
        messages: [],
    }),
    created() {
        this.api_url = process.env.VUE_APP_API_URL
    },
    async mounted() {
        this.loading = true
        this.checkSelectedCompany()
        this.getUserMessages()
        this.getUnreadMessages()
        await this.$echo.private('private-message.' + this.get_user.id)
            .listen('MessageSent', () => {
                this.refreshMessages()
            })
    },
    computed: {
        ...mapGetters({
            get_user: 'auth/get_user',
            get_selected_company: 'companies/get_selected_company',
            get_companies: 'companies/get_companies',
        }),
    },
    methods: {
        checkSelectedCompany() {
            if (this.get_selected_company.id) {
                this.is_company_selected = true
            }
        },
        async getUserMessages() {
            await this.$axios.get('api/users/get_user_messages')
                .then(({ data }) => {
                    if (data.response) {
                        this.users = data.users
                        this.user_messages = data.user_messages
                        this.loading = false
                    }
                })
        },
        selectUser(user, color) {
            this.selected_user = user
            this.color = color
            this.chat_loading = true
            this.unread_count = user.unread_count
            user.unread_count = 0
            this.getMessages()
        },
        refreshMessages() {
            this.getMessages()
            this.getUserMessages()
        },
        async getMessages() {
            if (this.selected_user == null)
                return
            this.chat_refreshing = true
            let payload = {
                sender_id: this.get_user.id,
                receiver_id: this.selected_user.id,
            }
            this.$axios.get('api/messages/get_messages_between', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.messages = data.data
                        this.$store.dispatch('messages/set_unread_count', data.unread.length)
                        this.chat_loading = false
                        this.chat_refreshing = false
                    }
                })
        },
        async getUnreadMessages() {
            let payload = {
                receiver_id: this.get_user.id,
                is_read: false,
            }
            this.$axios.get('api/messages/get_messages', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$store.dispatch('messages/set_unread_count', data.data.length)
                    }
                })
        },
        pushMessage(message) {
            this.messages.push(message)
            this.unread_count = 0
        },
    },
}
</script>

<style lang="scss" scoped></style>