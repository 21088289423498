import Vue from 'vue'
import VueRouter from 'vue-router'

import LoginRoute from '../components/login/routes/index.js'
import DashboardRoute from '../components/dashboard/routes/index.js'
import UserRoute from '../components/users/routes/index.js'
import UserActivityRoute from '../components/user_activities/routes/index.js'
import MeetingRoute from '../components/meetings/routes/index.js'
import ProjectRoute from '../components/projects/routes/index.js'
import MessageRoute from '../components/messages/routes/index.js'

Vue.use(VueRouter)

export default new VueRouter({
    mode: 'history',
    routes: [
        ...LoginRoute,
        ...DashboardRoute,
        ...UserRoute,
        ...UserActivityRoute,
        ...MeetingRoute,
        ...ProjectRoute,
        ...MessageRoute,
    ]
});