export default {
    namespaced: true,
    state: {
        unread_count: 0,
    },
    mutations: {
        set_unread_count(state, payload) {
            state.unread_count = payload
        },
    },
    getters: {
        get_unread_count: state => state.unread_count,
    },
    actions: {
        set_unread_count({ commit }, payload) {
            commit('set_unread_count', payload)
        },
    }
}