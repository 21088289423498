import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import AuthState from '../components/login/states/auth.js'
import CompaniesState from '../components/dashboard/states/companies.js'
import TasksState from '../components/dashboard/states/tasks.js'
import MeetingsState from '../components/meetings/states/meetings.js'
import ProjectsState from '../components/projects/states/projects.js'
import MessagesState from '../components/messages/states/messages.js'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth: AuthState,
        companies: CompaniesState,
        tasks: TasksState,
        meetings: MeetingsState,
        projects: ProjectsState,
        messages: MessagesState,
    },
    plugins: [createPersistedState()]
});