<template>
    <div>
        <b style="font-size:16px;">{{ sender_name }}</b>
        <p>{{ message }}</p>
    </div>
</template>

<script>
export default {
    props: [
        'sender_name', 'message',
    ],
}
</script>