import Vue from 'vue'
import dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(weekOfYear)
dayjs.extend(relativeTime)

Object.defineProperties(Vue.prototype, {
    $date: {
        get() {
            return dayjs
        }
    }
})